<template>
  <div>
    <div class="card card-custom card-stretch gutter-b farm-crops-conatiner">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header></t-farmon-table-header>
        <div class="card-toolbar toolbar-symbol">
          <b-button
            variant="dark"
            class="rounded-sm"
            size="sm"
            @click="showTaskModal()"
            >{{i18n.taskRegister}}</b-button
          >
        </div>
      </div>

      <div class="card-body pt-0 vuetify">
        <v-app id="inspire">
          <v-row class="fill-height">
            <v-col>
              <v-sheet class="mb-6">
                <div class="row align-items-center px-4">
                  <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                    Today
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <treeselect
                    v-model="categoriefilters"
                    :options="categoriesForFilter"
                    :multiple="true"
                    placeholder="카테고리 선택"
                    :normalizer="normalizer"
                    :searchable="false"                      
                    value-consists-of="ALL"
                    class="col-xl-4 p-0"
                  >
                    <label slot="option-label" slot-scope="{ node }">
                      <span class="px-2 mr-2" :style="`background-color: ${node.raw.color}`"></span>
                      {{ node.label }}
                    </label>
                    <label slot="value-label" slot-scope="{ node }">
                      <span class="px-2 mr-2" :style="`background-color: ${node.raw.color}`"></span>
                      {{ node.label }}
                    </label>
                  </treeselect>
                  <!-- <v-select
                      v-model="categoriefilters"
                      :items="categoriesForFilter"
                      clearable
                      deletable-chips
                      dense
                      outlined
                      label="카테고리 선택"
                      multiple
                      class="pt-7"
                      style="max-width: 450px;"
                    >
                    <template slot="selection" slot-scope="data">
                      <div class="mr-2 p-2">
                        <small>
                          <span class="px-2 mr-1" :style="`background-color: ${data.item.color};`"></span>
                          {{data.item.text}}
                        </small>
                      </div>
                    </template>
                    <template slot="item" slot-scope="data">
                      <div>
                        <span class="px-2 mr-2" :style="`background-color: ${data.item.color}`"></span>
                        {{data.item.text}}
                      </div>
                    </template>
                  </v-select> -->
                </div>
              </v-sheet>
              <v-sheet>
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :event-name="getEventName"
                  :event-more="false"
                  event-overlap-mode="stack"
                  @click:event="showEvent"
                  @mousedown:event="startDrag"
                  @mousedown:day="startTime"
                  @mousemove:day="mouseMove"
                  @mousemove:event="mouseMoveEvent"
                  @mouseup:day="endDrag"
                  @mouseleave.native="cancelDrag"
                  @change="getStartEndDates"
                  :locale="locale"
                ></v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  :offset-offset="true"
                  absolute
                  left
                >
                  <v-card color="grey lighten-4" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-toolbar-title class="font-weight-bold">
                        <div>{{selectedEvent.systemId == 0 ? '' : '[' + selectedEvent.connectNm + '] '}} {{ taskCategory[selectedEvent.taskCategoryId] }}</div>
                        <div>
                          <small class="pr-2"><b-icon icon="person" font-scale="1"></b-icon>{{ members[mno] }}</small>
                          <small class="pr-4">{{titleDateStart}} {{titleDateEnd == '' ? '' : ' ~ ' + titleDateEnd}}</small>
                        </div>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="showTaskEditModal(selectedEvent)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="deleteTask(selectedEvent)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn icon @click="selectedOpen = false">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <span class="text-dark text-h6 font-weight-normal">{{ selectedEvent.taskDetail }}</span>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </v-app>
      </div>

      <b-modal
          v-model="taskModal"
          size="xl"
          hide-footer
          hide-header
          no-close-on-backdrop
          no-close-on-esc
        >
        <div class="card card-custom card-stretch">
          <!--begin::Header-->
          <div class="card-header border-0 align-items-center">
            <h3 class="card-title align-items-start flex-column">
            <span class="title font-weight-bold">
              {{register == true ? i18n.taskRegister : i18n.taskEdit}}
            </span>
            </h3>
            <div class="card-toolbar toolbar-symbol">
              <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="closeTaskModal">
                <i class="la la-close font-size-h5"></i>
              </b-button>
            </div>
          </div>
          <!--end::Header-->
          <!-- begin::Body -->
          <div class="card-body pt-5 pb-5">
            <form class="form-horizontal">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{i18n.author}}</label>
                <label class="col-sm-3 col-form-label font-weight-normal">
                  {{members[mno]}}
                </label>
              </div>
              <div class="form-group row align-items-center">
                <label for="event-name" class="col-sm-2 col-form-label"> {{i18n.connectNm}} </label>
                <div class="col-sm-4">
                  <b-form-select v-model="systemId" class="bg-white" :options="connectOptions"></b-form-select>
                </div>
                <label for="event-name" class="col-sm-2 col-form-label">{{i18n.category}}</label>
                <div class="col-sm-4">
                  <!-- <b-form-select v-model="taskCategoryId" class="bg-white" :options="categories"></b-form-select> -->
                  <treeselect
                  v-model="taskCategoryId"
                  :options="categoriesForFilter"
                  placeholder="카테고리 선택"
                  :normalizer="normalizer"
                  :searchable="false"
                  :clearable="false"
                  >
                    <label slot="option-label" slot-scope="{ node }">
                      <span class="px-3 mr-2" :style="`background-color: ${node.raw.color}`"></span>
                      <span class="font-weight-normal"> {{ node.label }} </span>
                    </label>
                    <label slot="value-label" slot-scope="{ node }">
                      <span class="px-3 mr-2" :style="`background-color: ${node.raw.color}`"></span>
                      <span class="font-weight-normal"> {{ node.label }} </span>
                    </label>
                  </treeselect> 
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="min-date" class="col-sm-2 col-form-label">{{i18n.taskStartDate}}</label>
                <div class="col-sm-4">
                  <div class="input-group input-daterange">
                  <b-form-datepicker
                    v-model="taskStartDay"
                    :locale="locale"
                    class="farm-custom-datepicker"
                    placeholder="yy/mm/dd"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  ></b-form-datepicker>
                  <div class="input-group-prepend input-group-append">
                    <div class="input-group-text">.</div>
                  </div>
                  <b-form-timepicker
                    v-model="taskStartTime"
                    :locale="locale"
                    class="farm-custom-datepicker"
                    placeholder=''
                  ></b-form-timepicker>
                  </div>
                </div>
                <label class="col-sm-2 col-form-label">{{i18n.taskEndDate}}</label>
                <div class="col-sm-4">
                  <div class="input-group input-daterange">
                    <b-form-datepicker
                      v-model="taskEndDay"
                      :locale="locale"
                      class="farm-custom-datepicker"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      placeholder="yy/mm/dd"
                    ></b-form-datepicker>
                    <div class="input-group-prepend input-group-append">
                      <div class="input-group-text">.</div>
                    </div>
                    <b-form-timepicker
                      v-model="taskEndTime"
                      :locale="locale"
                      class="farm-custom-datepicker"
                      placeholder=''
                    ></b-form-timepicker>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label">{{i18n.taskDesc}}</label>
                <div class="col-sm-10">
                  <textarea v-model="taskDesc" type="text" class="farm-form-control form-control" rows="4" />
                </div>
              </div>
            </form>
          </div>
          <!-- end::Body -->
          <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="saveTask(register)"><span>{{register == true ? i18n.register : i18n.edit}}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="closeTaskModal"><span class="">{{i18n.cancel}}</span></b-button>
          </div>
          <!--end::Footer-->
          </div>
        </b-modal>
    </div>
  </div>
</template>

<style>
.v-calendar-weekly {
  display: table;
  table-layout: fixed;
}
.v-calendar-weekly__week {
  height: auto;
  display: table-row;
}
.v-calendar-weekly__day {
  min-height: 120px;
  display: table-cell;
  width: calc(100% / 7);
}
.vue-treeselect__value-remove {
  display:none;
}
.vue-treeselect__multi-value-item {
  color:black;
  background:none;
}
.vue-treeselect__checkbox-container {
  display:none;
}
.vue-treeselect__option--selected {
  background-color:rgb(223, 246, 255);
}
.vue-treeselect__label-container label {
  margin-top:3px;
  margin-bottom: 3px;
}
.vue-treeselect__multi-value-label label {
  margin-top:3px;
  margin-bottom: 3px;
}
.vue-treeselect__single-value label{
  font-size: 1rem;
}
.vue-treeselect__label-container label {
  font-size: 1rem;
}

</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ACT_GET_MY_FARM, ACT_GET_TASK_CATEGORY_LIST, ACT_GET_TASKS, ACT_INSERT_TASK, ACT_UPDATE_TASK, ACT_DELETE_TASK, ACT_GET_MEMBERS, ACT_GET_MY_CONNECTS} from '@/core/services/variable';
import { ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import { todayToDate, getItems, getItem, isSuccess, lan, utcToDate} from '@/core/services/funcs';
import TFarmonTableHeader from "@/components/TFarmonTableHeader";
import i18n from "@/core/plugins/vue-i18n.js";
import { mapGetters } from 'vuex';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "FarmCalendar",
  components: {
    TFarmonTableHeader,
    Treeselect,
  },
  mounted() {
    this.$refs.calendar.move(0)
    this.getCategories()
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: i18n.t("topNavBar.farm.info"), icon: "fas fa-clipboard" },
      { title: i18n.t("topNavBar.farm.farmCalendar") },
    ]);
    window.scrollTo(0, 0);
    this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
      this.fno = getItem(resp).fno
      this.getTasks()
    })
    // this.$store.dispatch(`${ACT_GET_TASK_CATEGORY_LIST}`).then(resp => {
    //   this.categories = []
    //   this.taskCategory = {}
    //   this.colors = ['']
    //   getItems(resp).forEach(item =>{
    //     this.categories.push({
    //       value: item.seq,
    //       text: item.taskCategory
    //     })
    //     this.taskCategory[item.seq] = item.taskCategory
    //     this.colors[item.seq] = item.taskColor
    //   })
    // })
    this.$store.dispatch(`${ACT_GET_MEMBERS}`, {pageSize: 9999}).then(resp => {
      getItems(resp).forEach(item => {
        this.members[item.mno] = item.memberNm
      })
    })
    this.$store.dispatch(ACT_GET_MY_CONNECTS).then(resp =>{
      this.connectOptions = [{value:0, text: '전체'}]

      if (getItem(resp) !== undefined) {
        getItem(resp).farmConnects.forEach(item => {
          this.connectOptions.push({
            value: item.systemId,
            text: item.connectNm
          })
        })
        this.systemId = this.connectOptions[0].value
      } else {
        this.connectOptions.push({value: 0, text: '-'})
      }
    })
  },
  data: () => ({
    focus: "",
    fno: 0,
    startYmd: '',
    endYmd: '',
    events: [],
    colors: [''],
    register: true,
    taskModal: false,
    taskStartTime: todayToDate('hh:mm'),
    taskStartDay: todayToDate('yyyy-MM-dd'),
    taskEndTime: todayToDate('hh:mm'),
    taskEndDay: todayToDate('yyyy-MM-dd'),
    locale: lan(),
    mno: 0,
    taskCategoryId: 1,
    taskCategory: {},
    members: {},
    categories: [],
    categoriesForFilter: [],
    categoriefilters: [],
    taskDesc: '',
    i18n: {
      taskRegister: i18n.t('farm.farmCalendar.taskRegister'),
      taskEdit: i18n.t('farm.farmCalendar.taskEdit'),
      category: i18n.t('farm.farmCalendar.category'),
      taskDesc: i18n.t('farm.farmCalendar.taskDesc'),
      register: i18n.t('farm.farmCalendar.register'),
      edit: i18n.t('farm.farmCalendar.edit'),
      cancel: i18n.t('farm.farmCalendar.cancel'),
      delete: i18n.t('farm.farmCalendar.delete'),
      registered: i18n.t('farm.farmCalendar.registered'),
      editted: i18n.t('farm.farmCalendar.editted'),
      deleted: i18n.t('farm.farmCalendar.deleted'),
      deleteAsk: i18n.t('farm.farmCalendar.deleteAsk'),

      invalidDesc: i18n.t('farm.farmCalendar.invalidDesc'),
      invalidDate: i18n.t('farm.farmCalendar.invalidDate'),
      author: i18n.t('farm.farmCalendar.author'),
      taskStartDate: i18n.t('farm.farmCalendar.taskStartDate'),
      taskEndDate: i18n.t('farm.farmCalendar.taskEndDate'),
      connectNm: i18n.t('farm.farmCalendar.connectNm'),
    },

    dragTime: null,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,

    isMouseOnEvent: false,

    selectedEvent: {},
    titleDateStart: '',
    titleDateEnd: '',
    selectedOpen: false,
    selectedElement: null,

    systemId : 0,
    connectNm :'',
    connectOptions: [],
  }),
  computed:{
    ...mapGetters(['myNm']),
  },
  watch:{
    categoriesForFilter(val){
      this.getTasks()
    },
    categoriefilters(val){
      this.getTasks()
    },
  },
  methods: {
    normalizer(node) {
      return {
        id: node.value,
        label: node.text,
        color: node.color,
        children: node.children,
      }
    },
    getTasks() {
      if(this.categoriesForFilter.length === 0) return
      if(this.fno === 0) return

      const params = {
        fnoList: `${this.fno}`,
        taskCategoryIdList: this.categoriefilters.length === 0 ? this.categoriesForFilter.map((val) => val.value).join(',') : this.categoriefilters.join(','),
        // taskCategoryId: '1,2,3,4,5,9',
        startYmd: this.startYmd,
        endYmd: this.endYmd,
        pageSize: 9999,
      }
      this.$store.dispatch(ACT_GET_TASKS, params).then(resp => {
        this.events = getItems(resp)
        for(let i in this.events){
          if(this.events[i].systemId !== 0) {
            this.events[i].name = '[' + this.events[i].connectNm + '] ' + this.events[i].taskDetail
          } else{
            this.events[i].name = this.events[i].taskDetail
          }
          this.events[i].color = this.colors[this.events[i].taskCategoryId]
          this.events[i].start = (new Date(this.events[i].taskStartDate)).getTime()
          this.events[i].end = (new Date(this.events[i].taskEndDate)).getTime()
          this.events[i].categoryNm = this.taskCategory[this.events[i].taskCategoryId]
        }
      })
    },
    getCategories(){
      this.categories = []
      this.categoriesForFilter = []
      this.categoriefilters = []
      this.parentCategories = [{value: null, text: '없음'}]
      this.colors = {}
      this.$store.dispatch(`${ACT_GET_TASK_CATEGORY_LIST}`).then(resp => {
        getItems(resp).forEach(item => {
          this.categories.push({value: item.seq, text: item.taskCategory, color: item.taskColor})
          if(item.parent === null){
            this.categoriesForFilter.push({value: item.seq, text: item.taskCategory, color: item.taskColor})
          } else {
            const parentCategory = this.categoriesForFilter.find(category => category.value === parseInt(item.parent))
            if(!parentCategory.children){
              parentCategory.children = []
            }
            parentCategory.children.push({value: item.seq, text: item.taskCategory, color: item.taskColor})
          }
          this.taskCategory[item.seq] = item.taskCategory
          this.colors[item.seq] = item.taskColor
          if(item.parent === null){
            this.parentCategories.push({value: item.seq, text: item.taskCategory})
          }
        })
        this.categoryItems = getItems(resp)
      })
    },
    getStartEndDates(event){
      let s = new Date(event.start.date), e = new Date(event.end.date)
      let sd = event.start.weekday, ed = event.end.weekday
      this.startYmd = utcToDate(new Date(s.getTime() - (sd * 24 * 60 * 60 * 1000)), 'yyyy-MM-dd')
      this.endYmd = utcToDate(new Date(e.getTime() + ((6-ed) * 24 * 60 * 60 * 1000)), 'yyyy-MM-dd')
      this.getTasks()
    },
    startDrag ({ nativeEvent, event }) {
      if(nativeEvent && event){
        this.selectedOpen = false
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        this.titleDateStart = this.selectedEvent.taskStartDate.slice(0,10)
        this.titleDateEnd = this.selectedEvent.taskStartDate.slice(0,10) !== this.selectedEvent.taskEndDate.slice(0,10) ? this.selectedEvent.taskEndDate.slice(0,10) : ''
      }
      if (event) {
        this.mno = event.mno
        this.dragEvent = event
        this.dragTime = null
      }
    },
    startTime (tms) {
      if(this.dragEvent) return
      const mouse = this.toTime(tms)
      this.createStart = mouse
      this.createEvent = {
        name: `#New Task`,
        color: this.colors[1],
        start: this.createStart,
        end: this.createStart,
      }
      this.events.push(this.createEvent)
    },
    mouseMoveEvent(){
      if (this.createEvent && this.createStart !== null) {
        this.isMouseOnEvent = true
      }
    },
    mouseMove (tms) {
      if(this.dragEvent) return
      const mouse = this.toTime(tms)

      if (this.createEvent && this.createStart !== null && !this.isMouseOnEvent) {
        const min = Math.min(mouse, this.createStart)
        const max = Math.max(mouse, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
      this.isMouseOnEvent = false
    },
    endDrag () {
      if(this.dragEvent){
        this.dragEvent = null
        return
      }
      this.showTaskModal(this.createEvent)

      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
    },
    cancelDrag () {
      if(this.dragEvent) return
      if (this.createEvent) {
        const i = this.events.indexOf(this.createEvent)
        if (i !== -1) {
          this.events.splice(i, 1)
        }
      }

      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getEventColor(event) {
      return event.color;
    },
    getEventName(event) {
      return event.input.name;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        this.titleDateStart = this.selectedEvent.taskStartDate.slice(0,10)
        this.titleDateEnd = this.selectedEvent.taskStartDate.slice(0,10) !== this.selectedEvent.taskEndDate.slice(0,10) ? this.selectedEvent.taskEndDate.slice(0,10) : ''
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    showTaskModal(item){
      this.register = true
      this.taskModal = true

      if (item == undefined) {
        this.taskStartDay = todayToDate('yyyy-MM-dd')
        this.taskStartTime = '00:00'
        this.taskEndDay = todayToDate('yyyy-MM-dd')
        this.taskEndTime = '00:00'
        this.taskCategoryId = 1
        this.taskDesc = ''
        this.mno = this.$store.state.auth.profile.mno
        this.systemId = 0
      } else {
        this.taskStartDay = utcToDate(item.start,'yyyy-MM-dd')
        this.taskStartTime = utcToDate(item.start, 'hh:mm')
        this.taskEndDay = utcToDate(item.end, 'yyyy-MM-dd')
        this.taskEndTime = utcToDate(item.end, 'hh:mm')
        this.taskCategoryId = 1
        this.taskDesc = ''
        this.mno = this.$store.state.auth.profile.mno
        this.systemId = 0
      }
    },
    showTaskEditModal(item){
      this.register = false
      this.taskModal = true
      this.taskId = item.seq
      this.taskStartDay = item.taskStartDate.slice(0,10)
      this.taskStartTime = item.taskStartDate.slice(11,16)
      this.taskEndDay = item.taskEndDate.slice(0,10)
      this.taskEndTime = item.taskEndDate.slice(11,16)
      this.taskCategoryId = item.taskCategoryId
      this.taskDesc = item.taskDetail
      this.mno = item.mno
      this.systemId = item.systemId
    },
    closeTaskModal(){
      this.taskModal = false
      this.getTasks()
    },
    deleteTask(item){
      const yesCallback = () =>{
        this.$store.dispatch(ACT_DELETE_TASK, item.seq).then(resp =>{
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.deleted, color: 'success'})
          }else {
            this.$store.dispatch(ACT_ADD_ALERT, {message: 'error', color: 'danger'})
          }
          this.getTasks()
        })
      }
      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.deleteAsk},
        callback: yesCallback
      })
    },
    saveTask(register){
      if(this.invalidCheck()){
        return
      }
      const params = {
        fno: this.fno,
        systemId: this.systemId,
        taskCategoryId: this.taskCategoryId,
        taskDetail: this.taskDesc,
        taskStartDate: this.taskStartDay + ' ' + this.taskStartTime,
        taskEndDate: this.taskEndDay + ' ' + this.taskEndTime,
      }

      if(register == true){
        this.$store.dispatch(ACT_INSERT_TASK, params).then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.registered, color: 'success'})
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: 'error', color: 'danger'})
          }
          this.closeTaskModal()
        })
      }else{
        params.seq = this.taskId
        this.$store.dispatch(ACT_UPDATE_TASK, params).then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.editted, color: 'success'})
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: 'error', color: 'danger'})
          }
          this.closeTaskModal()
        })
      }
    },
    invalidCheck(){
      let invalid = false, message = ''
      if(Number(this.taskStartDay.replaceAll('-','')) > Number(this.taskEndDay.replaceAll('-',''))){
        invalid = true
        message = this.i18n.invalidDate
      }else if(this.taskStartDay == this.taskEndDay){
        if(Number(this.taskStartTime.replaceAll(':','')) > Number(this.taskEndTime.replaceAll(':',''))){
          invalid = true
          message = this.i18n.invalidDate
        }
      }else if(this.taskDesc === ''){
        invalid = true
        message = this.i18n.invalidDesc
      }
      if(invalid === true) this.$store.dispatch(ACT_ADD_ALERT, {message: message, color: 'warning'})
      return invalid
    }
  }
}
</script>